import { Form } from '@/components/generic/forms/Form';
import { FormSubmitButton } from '@/components/generic/forms/FormButtons';
import {
  SelectMultipleInput,
  SelectSingleInput,
  SwitchInput,
  TextInput,
} from '@/components/generic/forms/FormFields';
import {
  useGetAreas,
  useGetPeriods,
  useGetUsers
} from '@/hooks';
import { Help, Lock, Public } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';

import { AuthGate } from '@/config/auth';
import { PERMISSION_OBJECTIVES_DUPLICATE_OBJECTIVE } from '@/config/permissions';
import { useCreateObjective, useDeleteObjective, useDuplicateObjective, useUpdateObjective } from '@/hooks/objective';
import formatOptions from '@/util/formatOptions';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as yup from 'yup';

function ObjectiveForm(props) {
  const {objective, onClose} = props;
  const [, setStateSelected] = useState(null);

  const {data: userResults} = useGetUsers();
  const {data: areaResults} = useGetAreas();
  const {data: periodsResults} = useGetPeriods();
  const {data: session} = useSession()
  const company = session?.user?.company
  const router = useRouter();


  const objectiveCreateMutation = useCreateObjective();
  const objectiveUpdateMutation = useUpdateObjective();
  const objectiveDeleteMutation = useDeleteObjective();
  const objectiveDuplicateMutation = useDuplicateObjective(objective?.id);

  const isEditingPage = !!objective?.id;

  return (
    <Form
      handleSubmit={(formValues, buttonProps) => {
        const values = {
          ...formValues,
          visibility_status: formValues.visibility_status ? 'PB' : 'PV',
        };
        isEditingPage
          ? objectiveUpdateMutation.mutateAsync({...objective, ...values})
            .then(() => {
              onClose? onClose() : null
            })
          : objectiveCreateMutation.mutateAsync({...objective, ...values})
            .then((data) => {
              if(onClose){
                onClose()
                return
              }
              if(buttonProps.name == 'sair') {
                router.push({pathname: "/" , query: {
                  owner_id: null,
                  area_id: data.area_id,
                  period_id: data.period_id,
                  objective_id: data.id
                  
                }})
              }
              if(buttonProps.name == "criar_mais"){
                router.push("/criar-objetivo")
              }
            });
      }}
    >
      <Card
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        {onClose && (
          <CardHeader
            title={isEditingPage ? 'Editar Objetivo' : 'Criar Objetivo'}
            action={
              <IconButton aria-label="settings" 
              onClick={(e) => {
                e.stopPropagation()
                onClose()
                }}>
                <Close />
              </IconButton>
            }
          />
        )}
        <CardContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Nome do Objetivo"
                name="name"
                validation={yup.string().required('Nome é obrigatório')}
                initialValue={objective?.name}
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <SelectSingleInput
                  label="Ciclo"
                  options={formatOptions(periodsResults?.filter((period) => {
                    const endDate = new Date(period.end_date).getTime(); 
                    return endDate >= Date.now(); 
                    })
                  )}
                  variant="standard"
                  name="period_id"
                  changeListener={(value) => {
                    setStateSelected(value);
                  }}
                  validation={yup.string().required('Ciclo é obrigatório')}
                  initialValue={isEditingPage? objective?.period_id : company?.current_period}
                />
            </Grid>
            <Grid item xs={6}>
              <SelectSingleInput
                label="Área"
                options={formatOptions(areaResults)}
                variant="standard"
                name="area_id"
                changeListener={(value) => {
                    setStateSelected(value);
                  }}
                initialValue={isEditingPage? objective?.area_id : session?.user?.area}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectSingleInput
                label="Responsável"
                options={formatOptions(userResults?.results)}
                variant="standard"
                name="owner_id"
                initialValue={objective?.owner_id}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectMultipleInput
                label="Participantes"
                options={formatOptions(userResults?.results)}
                variant="standard"
                name="participants"
                changeListener={(value) => {
                    setStateSelected(value);
                  }}
                initialValue={objective?.participants}
                />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Peso"
                name="weight"
                type="number"
                validation={yup.number().required('Peso é obrigatório')}
                initialValue={objective?.weight ?? 1}
              />
            </Grid>
            <Grid item xs={6}>
            <Typography sx={{mt: 0}}>
                  Visibilidade
                  <Tooltip
                    title="Um objetivo privado somente será visto pelos donos e participantes dele e de seus KeyResults. Um objetivo público será visto por todos da empresa"
                    arrow
                  >
                    <IconButton>
                      <Help />
                    </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={6}>
            <SwitchInput
                  name="visibility_status"
                  initialValue={objective?.visibility_status != 'PV'}
                  icon={<Lock />}
                  checkedIcon={<Public />}
                />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Descrição"
                name="description"
                multiline
                rows={4}
              initialValue={objective?.description}
            />
            </Grid>
            <Box md={4} sx={{
              width: '100%', 
              justifyContent: isEditingPage ? 'space-around' : 'center', 
              display: 'flex',
              gap: !isEditingPage ? 6 : 0
              }} >
              {isEditingPage && 
              <Button color='error' variant='contained' 
                startIcon={<Delete/>} 
                onClick={() => {
                  objectiveDeleteMutation.mutateAsync(objective)
                  .then(() => {                    
                    if(onClose) onClose()
                  })
                  }}>
                Deletar
              </Button>}
              {isEditingPage ?
                <>
                  <AuthGate permissions={PERMISSION_OBJECTIVES_DUPLICATE_OBJECTIVE}>
                    <Button color='primary' variant='outlined' 
                      onClick={() => {
                        objectiveDuplicateMutation.mutateAsync()
                        if(onClose) onClose()
                      }}>
                      Duplicar
                    </Button>
                  </AuthGate>
                  <FormSubmitButton color="primary" variant="contained">
                    Salvar
                  </FormSubmitButton>  
                </>
              :
                <>
                  <FormSubmitButton name="sair" color="primary" variant="outlined">
                    Salvar e Sair
                  </FormSubmitButton>
                  <FormSubmitButton name="criar_mais" color="primary" variant="contained" >
                    Salvar e Criar Mais
                  </FormSubmitButton>
                </>
              }
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
}

export default ObjectiveForm;